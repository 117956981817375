
import { Component } from "vue-property-decorator";
import { Action } from "vuex-class";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import CustomerList from "@/components/user/list/CustomerList.vue";
@Component({
  components: { CustomerList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // csv
  @Action("csv/userCustomerExport")
  public getCustomerCsv!: () => Promise<boolean>;

  public async download_btn() {
    await this.getCustomerCsv();
  }
}
