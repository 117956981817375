
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Getter, Mutation } from "vuex-class";
import { ICustomerListRequest, ICustomer } from "@/types/customer";
import { IPagination, ISelectItem } from "@/types";
import { IUserListRequest } from "@/types/user";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  // customer
  @Action("customer/userGetList")
  public getList!: (request: ICustomerListRequest) => boolean;

  @Getter("customer/list")
  public customerList!: ICustomer[];

  @Getter("customer/pagination")
  public pagination!: IPagination<ICustomer[]>;

  @Mutation("customer/clear")
  public customerClear!: () => void;

  // user
  @Action("user/userGetList")
  public getUserList!: (request: IUserListRequest) => boolean;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Mutation("user/clear")
  public userClear!: () => void;

  // watch
  @Watch("$route.query")
  public async changeQuery() {
    this.queryToParams();
    await this.getList(this.params);
  }

  // パラメータ定義
  public params: ICustomerListRequest = {
    search: "",
    page: 1,
    registration_status: 0,
    customer_user: 0
  };

  //
  // variables
  //
  public status_items = [
    { value: 0, text: "全て表示" },
    { value: Vue.prototype.$registrationStatus.registered, text: "登録済み" },
    {
      value: Vue.prototype.$registrationStatus.registration_scheduled,
      text: "登録予定"
    },
    {
      value: Vue.prototype.$registrationStatus.no_scheduled,
      text: "登録予定なし"
    }
  ];

  public headers = [
    { text: "顧客ID", value: "customer_mfid", sortable: false },
    { text: "顧客名", value: "name", sortable: false },
    { text: "読み仮名", value: "kana", sortable: false },
    { text: "短縮表示名", value: "short_name", sortable: false },
    { text: "支店名", value: "branch_name", sortable: false },
    { text: "郵便番号", value: "postalcode", sortable: false },
    { text: "住所", value: "address", sortable: false }
  ];

  //
  // methods
  //
  private async created() {
    this.customerClear();
    this.userClear();
    this.queryToParams();
    await Promise.all([
      this.getList(this.params),
      this.getUserList({ per_page: 0 })
    ]);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.params = {
      search: query.search ? decodeURI(query.search as string) : "",
      page: query.page ? Number(query.page) : 1,
      registration_status: query.registration_status
        ? Number(query.registration_status)
        : 0,
      customer_user: query.customer_user ? Number(query.customer_user) : 0
    };
  }

  // 1ページ目から検索
  // 主に検索条件が変更されたときに利用
  public async searchFirst() {
    this.params.page = 1;
    await this.paramsToQuery();
  }

  // URLのパラメータをセットする
  private paramsToQuery() {
    this.$router
      .push({
        path: this.$router.currentRoute.path,
        query: {
          search: this.params.search,
          page: this.params.page ? String(this.params.page) : "1",
          registration_status: this.params.registration_status
            ? String(this.params.registration_status)
            : "0",
          customer_user: this.params.customer_user
            ? String(this.params.customer_user)
            : "0"
        }
      })
      .catch(() => {});
  }

  // 行をクリックした際の挙動
  public rowClick(customer: ICustomer) {
    this.$router.push(`/user/customer/${customer.id}`);
  }
}
